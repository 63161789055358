import React, { Fragment } from 'react'
import './chairman.css'
import { Link } from 'react-router-dom'
const ChairMan = () => {
    return (
        <Fragment>
            <div className='chairman-director-container'>
                <div className='chairman-director-row'>
                    <div className='chairman column'>
                        <div className='chairman-image-container'>
                            <img src="images/chairman-image.jpg" alt="Shri. S. Manjit Singh Bhatti" className='chairman-image' />
                            <div className='chairma-content'>
                                <h1 className="chairman-name">Shri. S. Manjit Singh Bhatti</h1>
                                <p className='person-post'>Hon. Chairman</p>
                                <button className='read-message'><Link to="/chairman-message" className='read-message-link'>Chairman's Message</Link></button>
                            </div>
                        </div>
                    </div>
                    <div className='director column'>
                        <div className='director-image-container'>
                            <img src="images/dr_raman_kumar_sailly.JPG" alt="Prof. Dr. R K Saily" className='chairman-image' />
                            <div className='chairma-content'>
                            <h1 className="chairman-name">Prof. Dr. R K Saily</h1>
                            <p className='person-post'>Director</p>
                            <button className='read-message'><Link to="/director-message" className='read-message-link'>Director's Message</Link></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ChairMan