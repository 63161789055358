import React, { Fragment, useEffect, useState } from "react";
import "./corefacultydetails.css";
import { SocialIcon } from "react-social-icons";
import { FaEye } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";
const CoreFacultyDetails = () => {
	const loc = useParams();
	let slug = loc.corefacultydetails;

	const [coreDetails, setCoreDetails] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadPageData = async () => {
			setLoading(true);

			const response = await api({
				method: "post",
				url: "fetch.php",
				data: {
					get_ind_core_facult: true,
					slug: slug,
				},
				headers: { "Content-Type": "multipart/form-data" },
			});
			const jsonData = await response.data;
			setCoreDetails(jsonData.data[0]);
			setLoading(false);
		};
		if (loading) loadPageData();
	}, [slug]);
	// console.log(coreDetails);
	// console.log(coreDetails);
	return (
		<Fragment>
			{loading && <Loading />}

			<div className="core-faculty-container">
				<div className="core-faculty-profile-container">
					<div className="core-faculty-details-contant">
						<div className="core-faculty-profile-image">
							<img
								src={coreDetails.faculty_profile}
								alt="image alt"
								className="core-faculty-details-image"
							/>
						</div>
						<div className="core-faculty-prfile-name">
							<div className="core-faculty-name-email">
								<h3>{coreDetails.faculty_name}</h3>
								<div className="faculty-social-details-container">
									{/* <SocialIcon
										network="facebook"
										style={{ height: 30, width: 30 }}
										bgColor="#fff"
										className="social-media-links facebook faculty-social-media"
									/> */}
									<a
										href={coreDetails.faculty_linkedinUrl}
										target="_blanks"
									>
										<SocialIcon
											network="linkedin"
											style={{ height: 30, width: 30 }}
											bgColor="#fff"
											className="social-media-links linkedin faculty-social-media"
										/>
									</a>
									<br className="break-tag-hidden-desktop"></br>
									<div className="faculty-social-details-email-container">
										<strong>Mail :</strong>
										<p>{coreDetails.faculty_email}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="core-faculty-details-experience container"
					dangerouslySetInnerHTML={{
						__html: coreDetails && coreDetails.faculty_about,
					}}
				></div>
				<div className="core-faulty-detail-qualification-heading container">
					<p>Qualifications</p>
				</div>
				<div
					className="core-faulty-detail-qualification-content container"
					dangerouslySetInnerHTML={{
						__html:
							coreDetails && coreDetails.faculty_qualification,
					}}
				></div>
				<div className="core-faculty-details-pdf-container container">
					{coreDetails.faculty_past_pdf && (
						<div className="core-faculty-details-single-pdf-container">
							<div className="core-faculty-pasr-experience">
								<p>Past Experience</p>
							</div>
							<a
								href={coreDetails.faculty_past_pdf}
								target="_blank"
								className="core-faculty-expierence-pdf  d-inline-block"
							>
								<FaEye className="core-faculty-expierence-pdf-view" />
							</a>
						</div>
					)}
					{coreDetails.faculty_participants_pdf && (
						<div className="core-faculty-details-single-pdf-container">
							<div className="core-faculty-pasr-experience">
								<p>Participations</p>
							</div>
							<a
								href={coreDetails.faculty_participants_pdf}
								target="_blank"
								className="core-faculty-expierence-pdf d-inline-block"
							>
								<FaEye className="core-faculty-expierence-pdf-view" />
							</a>
						</div>
					)}
					{coreDetails.faculty_publication_pdf && (
						<div className="core-faculty-details-single-pdf-container">
							<div className="core-faculty-pasr-experience">
								<p>Publications</p>
							</div>
							<a
								href={coreDetails.faculty_publication_pdf}
								target="_blank"
								className="core-faculty-expierence-pdf  d-inline-block"
							>
								<FaEye className="core-faculty-expierence-pdf-view" />
							</a>
						</div>
					)}
					{coreDetails.faculty_staff_achievements_pdf && (
						<div className="core-faculty-details-single-pdf-container">
							<div className="core-faculty-pasr-experience">
								<p>Staff Achievements</p>
							</div>
							<a
								href={
									coreDetails.faculty_staff_achievements_pdf
								}
								target="_blank"
								className="core-faculty-expierence-pdf  d-inline-block"
							>
								<FaEye className="core-faculty-expierence-pdf-view" />
							</a>
						</div>
					)}
					{coreDetails.faculty_staff_assignment_pdf && (
						<div className="core-faculty-details-single-pdf-container">
							<div className="core-faculty-pasr-experience">
								<p>Staff Assignment</p>
							</div>
							<a
								href={coreDetails.faculty_staff_assignment_pdf}
								target="_blank"
								className="core-faculty-expierence-pdf  d-inline-block"
							>
								<FaEye className="core-faculty-expierence-pdf-view" />
							</a>
						</div>
					)}
					{coreDetails.faculty_research_paper_pdf && (
						<div className="core-faculty-details-single-pdf-container">
							<div className="core-faculty-pasr-experience">
								<p>Research Paper</p>
							</div>
							<a
								href={coreDetails.faculty_research_paper_pdf}
								target="_blank"
								className="core-faculty-expierence-pdf  d-inline-block"
							>
								<FaEye className="core-faculty-expierence-pdf-view" />
							</a>
						</div>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default CoreFacultyDetails;
