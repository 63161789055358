import React, { Fragment, useEffect, useState } from "react";
import "./admissionenquiryform.css";
import { api } from "./../../helper/api";
import { toast } from "react-toastify";
import Loading from "../Loading/Loading";
import {
	EmailInput,
	GenricInput,
	MobileNumberInput,
	SelectField,
	TextInput,
} from "../InputFeilds";

const AdmissionEnquiryForm = () => {
	const [formData, updateFormData] = useState({
		online_admission_form: true,
		fullname: "",
		email: "",
		phone: "",
		gender: "",
		college_name: "",
		age: "",
	});
	const [loading, setLoading] = useState(false);
	const [isValidated, setIsValidated] = useState(false);

	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		updateFormData({
			...formData,
			online_admission_form: true,
		});
		const response = await api({
			method: "post",
			url: "inquiry",
			data: formData,
			headers: { "Content-Type": "multipart/form-data" },
		});
		const jsonData = await response.data;

		if (
			jsonData?.status_message === "Form Submitted" &&
			jsonData?.status === 200
		) {
			setLoading(false);
			toast.success("Your Inquiry Submitted Successfully");
			window.location.reload();
		} else {
			setLoading(false);
			toast.error("Something Occur! Please try again later");
		}
	};

	const [data, setData] = useState(null);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_admission_rq: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}
			<div className="admission-enquiry-form-container">
				<div className="admission-enquiry-form">
					<form
						className="admission-enquiry-form-start container"
						onSubmit={handleSubmit}
					>
						<TextInput
							label={"Full Name"}
							id="fullname"
							onChange={handleChange}
							placeholder="Enter Name"
							defaultValue={formData?.fullname}
							isValidated={isValidated}
							required
						/>
						<div className="admission-enquiry-gender-age">
							<div className="admission-enquiry-gender-container">
								<SelectField
									label={"Gender"}
									id="gender"
									onChange={handleChange}
									placeholder="Select Gender"
									className={"mt-3"}
									options={[
										{
											name: "Male",
											value: "Male",
										},
										{
											name: "Female",
											value: "Female",
										},
									]}
									isValidated={isValidated}
									defaultValue={formData?.gender}
									required
								/>
							</div>
							<div className="admission-enquiry-ag-container">
								<GenricInput
									type="number"
									maxlength={"2"}
									label={"Age"}
									id="age"
									className={"mt-3"}
									onChange={handleChange}
									placeholder="Enter Age"
									defaultValue={formData?.age}
									isValidated={isValidated}
									required
								/>
							</div>
						</div>
						<TextInput
							label={"College Name"}
							id="college_name"
							onChange={handleChange}
							className={"mt-3"}
							placeholder="Enter College Name"
							defaultValue={formData?.college_name}
							isValidated={isValidated}
							required
						/>
						<EmailInput
							label={"Email ID"}
							id="email"
							className={"mt-3"}
							onChange={handleChange}
							isValidated={isValidated}
							defaultValue={formData?.email}
							placeholder="Enter email"
							required
						/>
						<MobileNumberInput
							label={"Contact Number"}
							id="phone"
							onChange={handleChange}
							placeholder="Enter Number"
							className={"mt-3"}
							maxlength={"10"}
							isValidated={isValidated}
							defaultValue={formData?.phone}
							required
						/>
						<SelectField
							label={"Further Study"}
							id="further_study"
							onChange={handleChange}
							placeholder="Select Department"
							className={"mt-3"}
							options={[
								{
									name: "Certificate Course",
									value: "Certificate Course",
								},
								{
									name: "Master Of Management Studies(M.M.S)",
									value: "Master Of Management Studies(M.M.S)",
								},
								{
									name: "Master Of Management Studies(M.M.S)",
									value: "Master Of Management Studies(M.M.S)",
								},
								{
									name: "Upcoming Events",
									value: "Upcoming Events",
								},
							]}
							isValidated={isValidated}
							defaultValue={formData?.further_study}
							required
						/>

						<div className="admission-enquiry-form-btn-container">
							<button
								type="submit"
								className="admission-enquiry-form-submit-btn"
							>
								Submit
							</button>
							<button
								type="reset"
								className="admission-enquiry-form-reset-btn"
							>
								Reset
							</button>
						</div>
					</form>
				</div>
				<div className="admission-enquiry-form-points-container container">
					{data && data.length > 0 && (
						<ul>
							{data.map((item, id) => (
								<li key={id}>
									<a
										href={
											item.pdf
												? item.pdf
												: // eslint-disable-next-line no-script-url
												  "javascript:void(0);"
										}
										rel="noopener noreferrer"
										target="_blank"
										className="admissionLink"
									>
										{item.title}
									</a>
								</li>
							))}
						</ul>
					)}
				</div>
			</div>
		</Fragment>
	);
};

export default AdmissionEnquiryForm;
