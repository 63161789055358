import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Footer from "./components/footer/Footer";
import TopHEader from "./components/topheader/TopHeader";
import { Fragment } from "react";
import NavBar from "./components/navbar/NavBar";
import Index from "./components/pages/Index";
import { Route, Routes } from "react-router-dom";
import VisionMissions from "./components/pages/VisionMissions";
import CoreValues from "./components/pages/CoreValues";
import AboutUsPage from "./components/pages/AboutUsPage";
import ChairManMessagePage from "./components/pages/ChairManMessagePage";
import DirectorMessagePage from "./components/pages/DirectorMessagePage";
import CollegeDevelopmentCommitteePage from "./components/pages/CollegeDevelopmentCommittee";
import GoverningBodyPages from "./components/pages/GoverningBodyPages";
import HrPolicyPage from "./components/pages/HrPolicyPage";
import MandatoryDisclosurePage from "./components/pages/MandatoryDisclosurePage";
import MasterManagementStudyPage from "./components/pages/MasterManagementStudyPage";
import ExamTimeTablePage from "./components/pages/ExamTimeTablePage";
import UpcomingEventsPage from "./components/pages/UpcomingEventsPage";
import ExaminationTimeTablePage from "./components/pages/ExaminationTimeTablePage";
import DynamicResultPage from "./components/pages/DynamicResultPage";
import AcademicCalendarPage from "./components/pages/AcademicCalendarPage";
import MMSSyllabusPage from "./components/pages/MMSSyllabusPage";
import ResearchCellPage from "./components/pages/ResearchCellPage";
import ConsultingPage from "./components/pages/ConsultingPage";
import ResearchJournalPage from "./components/pages/ResearchJournalPage";
import CodeOfEthicsPage from "./components/pages/CodeOfEthicsPage";
import DevelopmentInitiativePage from "./components/pages/DevelopmentInitiativePage";
import BridgeCourses from "./components/capability-enhancement-pages/BridgeCourses";
import SoftSkillDevelopment from "./components/capability-enhancement-pages/SoftSkillDevelopment";
import CareerCounselling from "./components/capability-enhancement-pages/CareerCounselling";
import BestPractices from "./components/capability-enhancement-pages/BestPractices";
import CapabilityEnhancementPage from "./components/pages/CapabilityEnhancementPage";
import PublicationsPage from "./components/pages/PublicationsPage";
import ResearchPaperPage from "./components/pages/ResearchPaperPage";
import CoCurricular from "./components/pages/CoCurricular";
import ExtracurricularEventsPage from "./components/pages/ExtracurricularEventsPage";
import ConferencePage from "./components/pages/ConferencePage";
import CoreFacultyPages from "./components/pages/CoreFacultyPages";
import CoreFacultyDetailsPage from "./components/pages/CoreFacultyDetailsPage";
import VisitingGuestPage from "./components/pages/VisitingGuestPage";
import InstitusionAchievementsPage from "./components/pages/InstitusionAchievementsPage";
import BestResearchPaperPage from "./components/pages/BestResearchPaperPage";
import StudentsAchievementPages from "./components/pages/StudentsAchievementPages";
import PlacementPage from "./components/pages/PlacementPage";
import MandatoryCommitteesPage from "./components/pages/MandatoryCommitteesPage";
import CommitteesStudentsForumPage from "./components/pages/CommitteesStudentsForumPage";
import StudentCouncilPage from "./components/pages/StudentCouncilPage";
import MassiveOnlineCoursesPage from "./components/pages/MassiveOnlineCoursesPage";
import FacultyDevelopmentProgramPage from "./components/pages/FacultyDevelopmentProgramPage";
import ManagementDevProgramPage from "./components/pages/ManagementDevProgramPage";
import RegisterForFDPMDPPage from "./components/pages/RegisterForFDPMDPPage";
import StudentWelfareServicesPage from "./components/pages/StudentWelfareServicesPage";
import ProspectusPage from "./components/pages/ProspectusPage";
import PhotoGalleryPage from "./components/pages/PhotoGalleryPage";
import PrivacyPolicyPage from "./components/pages/PrivacyPolicyPage";
import RefundPolicyPage from "./components/pages/RefundPolicyPage";
import TermsAndConditionsPage from "./components/pages/TermsAndConditionsPage";
import CollegeCommitteesPage from "./components/pages/CollegeCommitteesPage";
import MousPage from "./components/pages/MousPage";
import NIRFPage from "./components/pages/NIRFPage";
import FAQPage from "./components/pages/FAQPage";
import StudyMaterialPage from "./components/pages/StudyMaterialPage";
import FeedBackPage from "./components/pages/FeedBackPage";
import ContactUsPage from "./components/pages/ContactUsPage";
import NaccPage from "./components/pages/NaccPage";
import EventRegistrationPage from "./components/pages/EventRegistrationPage";
import AdmissionForm from "./components/pages/AdmissionForm";
import AdmissionPage from "./components/pages/AdmissionPage";
import BestPracticeDetailsPage from "./components/pages/BestPracticeDetailsPage";
import AutoScrollToTop from "./components/auto-scroll-to-top/AutoScrollToTop";
import CertificateCoursePage from "./components/pages/CertificateCoursePage";
import OrganizationStructurePage from "./components/pages/OrganizationStructurePage";
import AccoladesPage from "./components/pages/AccoladesPage";
import AdmissionEnquiryPage from "./components/pages/AdmissionEnquiryPage";
import BestPracticesBootCamp from "./components/best-practices-bootcamp/BestPracticesBootCamp";
import BestPracticesBootCampPage from "./components/pages/BestPracticesBootCampPage";
import IGLTwoPointOPage from "./components/pages/IGLTwoPointOPage";
import RuralImmersionProgram from "./components/pages/RuralImmersionProgramPage";
import RuralImmersionProgramPage from "./components/pages/RuralImmersionProgramPage";
import BestPractciesIGLPage from "./components/pages/BestPractciesIGLPage";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import NaccInsidePage from "./components/pages/NaccInsidePage";
import AcademicYearDetailsPage from "./components/pages/AcademicYearDetailsPage";
import ResarchPaperDetailsPage from "./components/pages/ResarchPaperDetailsPage";
import BestResarchPaperDetailsPage from "./components/pages/BestResarchPaperDetailsPage";
import OtherEventsPage from "./components/pages/OtherEventsPage";
import TeacherRegistrationPage from "./components/pages/TeacherRegistrationPage";

function App() {
	return (
		<Fragment>
			<div className="App">
				<ToastContainer />

				<AutoScrollToTop />
				<TopHEader></TopHEader>
				<NavBar></NavBar>
				<Routes>
					<Route
						path="/"
						element={<Index page_title="Home Page" />}
					/>
					<Route
						path="/vision-missions"
						element={
							<VisionMissions page_title="Vision & Mission" />
						}
					/>
					<Route
						path="/about-us"
						element={<AboutUsPage page_title="About Us" />}
					/>
					<Route
						path="/core-values"
						element={<CoreValues page_title="Core Values" />}
					/>
					<Route
						path="/chairman-message"
						element={
							<ChairManMessagePage page_title="Chairman's Message" />
						}
					/>
					<Route
						path="/director-message"
						element={
							<DirectorMessagePage page_title="Director's Message" />
						}
					/>
					<Route
						path="/college-development-committee"
						element={
							<CollegeDevelopmentCommitteePage page_title="College Development Committee" />
						}
					/>
					<Route
						path="/governing-body"
						element={
							<GoverningBodyPages page_title="Governing Body" />
						}
					/>
					<Route
						path="/accolades"
						element={<AccoladesPage page_title="Accolades" />}
					/>
					<Route
						path="/organizational-structure"
						element={
							<OrganizationStructurePage page_title="Organizational Structure" />
						}
					/>
					<Route
						path="/hr-policy"
						element={<HrPolicyPage page_title="HR Policy" />}
					/>
					<Route
						path="/mandatory-disclosures"
						element={
							<MandatoryDisclosurePage page_title="Mandatory Disclosure" />
						}
					/>
					<Route
						path="/master-of-management-studies"
						element={
							<MasterManagementStudyPage page_title="Master Of Management Studies (M.M.S)" />
						}
					/>
					<Route
						path="/admission-enquiry"
						element={
							<AdmissionEnquiryPage page_title="Admission Enquiry" />
						}
					/>
					<Route
						path="/exam-time-table"
						element={
							<ExamTimeTablePage page_title="Exam Time Table" />
						}
					/>
					<Route
						path="/certificate-course"
						element={
							<CertificateCoursePage page_title="Certificate Courses" />
						}
					/>
					<Route
						path="/upcoming-events"
						element={
							<UpcomingEventsPage page_title="Upcoming Events" />
						}
					/>
					<Route
						path="/teacher-registration"
						element={
							<TeacherRegistrationPage page_title="Teacher Registration" />
						}
					/>
					<Route
						path="/mms-time-table"
						element={
							<ExaminationTimeTablePage page_title="Examination Time Table" />
						}
					/>
					<Route
						path="/results"
						element={<DynamicResultPage page_title="Results" />}
					/>
					<Route
						path="/academic-calendar"
						element={
							<AcademicCalendarPage page_title="Academic Calendar" />
						}
					/>
					<Route
						path="/master-of-management-studies/syllabus"
						element={<MMSSyllabusPage page_title="Syllabus" />}
					/>
					<Route
						path="/researchcell"
						element={
							<ResearchCellPage page_title="Research Cell" />
						}
					/>
					<Route
						path="/publications"
						element={<PublicationsPage page_title="Publications" />}
					/>
					<Route
						path="/publications/:year"
						element={
							<AcademicYearDetailsPage page_title="Publications" />
						}
					/>
					<Route
						path="/publications/:year/:level"
						element={
							<AcademicYearDetailsPage page_title="Publications" />
						}
					/>
					<Route
						path="/research-paper"
						element={
							<ResearchPaperPage page_title="Research Paper" />
						}
					/>
					<Route
						path="/research-paper/:year"
						element={
							<ResarchPaperDetailsPage page_title="Research Paper" />
						}
					/>
					<Route
						path="/research-paper/:year/:paper/:level"
						element={
							<ResarchPaperDetailsPage page_title="Research Paper" />
						}
					/>
					<Route
						path="/best-research-paper/:year"
						element={
							<BestResarchPaperDetailsPage page_title="Best Research Paper" />
						}
					/>
					<Route
						path="/best-research-paper/:year/:paper/:level"
						element={
							<BestResarchPaperDetailsPage page_title="Best Research Paper" />
						}
					/>
					<Route
						path="/consulting"
						element={<ConsultingPage page_title="Consulting" />}
					/>
					<Route
						path="/research-journal"
						element={
							<ResearchJournalPage page_title="Research Journal" />
						}
					/>
					<Route
						path="/code-of-ethics"
						element={
							<CodeOfEthicsPage page_title="Code Of Ethics" />
						}
					/>
					<Route
						path="/development-initiative"
						element={
							<DevelopmentInitiativePage page_title="Overview" />
						}
					/>
					<Route
						path="/capapibilty-enhancement-development-schemes"
						element={
							<CapabilityEnhancementPage page_title="Capability Enhancement And Developmemnt Schemes" />
						}
					/>
					<Route
						path="/capapibilty-enhancement-development-schemes/:tab"
						element={<BridgeCourses page_title="Bridge Course" />}
					/>
					<Route
						path="/soft-skill-development"
						element={
							<SoftSkillDevelopment page_title="Soft Skill Development" />
						}
					/>
					<Route
						path="/career-counselling"
						element={
							<CareerCounselling page_title="Career Counselling" />
						}
					/>
					<Route
						path="/best-practices"
						element={<BestPractices page_title="Best Practices" />}
					/>
					<Route
						path="/best-practices/:bestpracticesyear"
						element={<BestPracticeDetailsPage />}
					/>
					<Route
						path="/best-practices/:tab_slug/:subtab_slug"
						element={<BestPracticesBootCampPage />}
					/>
					<Route
						path="/best-practices/:bestpracticesyear/igl-2-o/:bestpracticebootcamp"
						element={<IGLTwoPointOPage />}
					/>
					<Route
						path="/best-practices/:bestpracticesyear/igl-2-o/:bestpracticebootcamp/rular-immersion-program"
						element={<RuralImmersionProgramPage />}
					/>
					<Route
						path="/best-practices/:bestpracticesyear/igl-2-o/:bestpracticebootcamp/igl"
						element={<BestPractciesIGLPage />}
					/>
					<Route
						path="/co-curricular"
						element={
							<CoCurricular page_title="Co-Curricular Events" />
						}
					/>
					<Route
						path="/other-events"
						element={<OtherEventsPage page_title="Other Events" />}
					/>
					<Route
						path="/extracurricular"
						element={
							<ExtracurricularEventsPage page_title="Extracurricular Events" />
						}
					/>
					<Route
						path="/conference"
						element={<ConferencePage page_title="Conference" />}
					/>
					<Route
						path="/core-faculty"
						element={<CoreFacultyPages page_title="Core Faculty" />}
					/>
					<Route
						path="/core-faculty/:corefacultydetails"
						element={<CoreFacultyDetailsPage />}
					/>
					<Route
						path="/visiting-and-guest"
						element={
							<VisitingGuestPage page_title="Visiting & Guest Faculty" />
						}
					/>
					<Route
						path="/instituion-achievements"
						element={
							<InstitusionAchievementsPage page_title="Institute Achievements" />
						}
					/>
					<Route
						path="/best-research-paper"
						element={
							<BestResearchPaperPage page_title="Best Research Paper" />
						}
					/>
					<Route
						path="/students-achievements"
						element={
							<StudentsAchievementPages page_title="Students' Achievement" />
						}
					/>
					<Route
						path="/placements"
						element={<PlacementPage page_title="Placements" />}
					/>
					<Route
						path="/mandatory-committees"
						element={
							<MandatoryCommitteesPage page_title="Mandatory Committees" />
						}
					/>
					<Route
						path="/committees-of-students-forum"
						element={
							<CommitteesStudentsForumPage page_title="Committees of Students Forum" />
						}
					/>
					<Route
						path="/student-council"
						element={
							<StudentCouncilPage page_title="Student Council" />
						}
					/>
					<Route
						path="/massive-open-online-courses"
						element={
							<MassiveOnlineCoursesPage page_title="Massive  Open Online Courses (MOOC)" />
						}
					/>
					<Route
						path="/faculty-development-program"
						element={
							<FacultyDevelopmentProgramPage page_title="Faculty Development Program (FDP)" />
						}
					/>
					<Route
						path="/management-development-program"
						element={
							<ManagementDevProgramPage page_title="Management Development Program (MDP)" />
						}
					/>
					<Route
						path="/register-for-fdp-mdp"
						element={
							<RegisterForFDPMDPPage page_title="Faculty Development Program Registration" />
						}
					/>
					<Route
						path="/student-welfare-services"
						element={
							<StudentWelfareServicesPage page_title="Students Welfare Services" />
						}
					/>
					<Route
						path="/prospectus"
						element={<ProspectusPage page_title="Prospectus" />}
					/>
					<Route
						path="/photo-gallery"
						element={
							<PhotoGalleryPage page_title="Photo Gallery" />
						}
					/>
					<Route
						path="/privacy-policy"
						element={
							<PrivacyPolicyPage page_title="Privacy Policy" />
						}
					/>
					<Route
						path="/terms-and-conditions"
						element={
							<TermsAndConditionsPage page_title="Terms & Conditions" />
						}
					/>
					<Route
						path="/refund-policy"
						element={
							<RefundPolicyPage page_title="Refund Policy" />
						}
					/>
					<Route
						path="/college-committees"
						element={
							<CollegeCommitteesPage page_title="College Committee" />
						}
					/>
					<Route
						path="/mous"
						element={<MousPage page_title="MOU's" />}
					/>
					<Route
						path="/nirf_d"
						element={<NIRFPage page_title="NIRF" />}
					/>
					<Route path="/faq" element={<FAQPage page_title="FAQ" />} />
					<Route
						path="/feedback"
						element={<FeedBackPage page_title="Feedback" />}
					/>
					<Route
						path="/download-study-material"
						element={<StudyMaterialPage page_title="e-Resources" />}
					/>
					<Route
						path="/contact-us"
						element={<ContactUsPage page_title="Contact Us" />}
					/>
					<Route
						path="/naac/:year"
						element={<NaccPage page_title="NAAC" />}
					/>
					<Route
						path="/naac/:year/:page"
						element={<NaccInsidePage page_title="NAAC" />}
					/>
					<Route
						path="/event-registration"
						element={
							<EventRegistrationPage page_title="Event Registration" />
						}
					/>
					<Route
						path="/online-enquiry-form"
						element={
							<AdmissionForm page_title="Online Admission Form" />
						}
					/>
					<Route
						path="/admission"
						element={
							<AdmissionPage page_title="Fill-up the following information and proceed to ONLINE PAYMENT" />
						}
					/>
				</Routes>
				<Footer></Footer>
			</div>
		</Fragment>
	);
}

export default App;
