import React, { Fragment, useEffect, useState } from "react";
import "./admissionenquiry.css";
import { Link } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { api } from "../../helper/api";
import Loading from "../Loading/Loading";

const AdmissionEnquiry = () => {
	const [toggleTabs, setToggleTabs] = useState(1);
	const toggleTabContent = (index) => {
		setToggleTabs(index);
	};
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const loadMaterial = async () => {
			try {
				const response = await api({
					method: "post",
					url: "fetch.php",
					data: { fetch_admission_details: true },
					headers: { "Content-Type": "multipart/form-data" },
				});
				const jsonData = await response.data;
				setData(jsonData.data[0]);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		loadMaterial();
	}, []);
	return (
		<Fragment>
			{loading && <Loading />}

			<div className="admission-enquiry-container">
				<div className="admission-enquiry-row">
					<div className="admission-enquiry-tabs-left-column">
						<div
							className={
								toggleTabs === 1
									? "active-admission-enquiry-left-tabs"
									: "admission-enquiry-left-tabs"
							}
							onClick={() => {
								toggleTabContent(1);
							}}
						>
							<h5>Admission policy</h5>
						</div>
						<div
							className={
								toggleTabs === 2
									? "active-admission-enquiry-left-tabs"
									: "admission-enquiry-left-tabs"
							}
							onClick={() => {
								toggleTabContent(2);
							}}
						>
							<h5>Fee Structure</h5>
						</div>
						<div
							className={
								toggleTabs === 3
									? "active-admission-enquiry-left-tabs"
									: "admission-enquiry-left-tabs"
							}
							onClick={() => {
								toggleTabContent(3);
							}}
						>
							<h5>Eligibilty Criteria</h5>
						</div>
						<div
							className={
								toggleTabs === 4
									? "active-admission-enquiry-left-tabs"
									: "admission-enquiry-left-tabs"
							}
							onClick={() => {
								toggleTabContent(4);
							}}
						>
							<h5>Course Intake</h5>
						</div>
						<div
							className={
								toggleTabs === 5
									? "active-admission-enquiry-left-tabs"
									: "admission-enquiry-left-tabs"
							}
						>
							<Link to="/online-enquiry-form">
								Online Admission Form
							</Link>
						</div>
						<div
							className={
								toggleTabs === 6
									? "active-admission-enquiry-left-tabs"
									: "admission-enquiry-left-tabs"
							}
						>
							<a href={data && data.brochure}>
								E-Brochure For MMS
							</a>
						</div>
					</div>
					<div className="admission-enquiry-tables-right-column container">
						<div
							className={
								toggleTabs === 1
									? "active-admission-enquiry-tables"
									: "admission-enquiry-tables"
							}
							dangerouslySetInnerHTML={{
								__html: data && data.admission_policy,
							}}
						></div>

						<div
							className={
								toggleTabs === 2
									? "active-admission-enquiry-tables"
									: "admission-enquiry-tables"
							}
						>
							<div
								dangerouslySetInnerHTML={{
									__html: data && data.fee_structure,
								}}
							></div>
							<div className="admission-enquiry-fees-structure-table-container container">
								<table className="admission-enquiry-fees-structure-table">
									<tr>
										<td>Fee Structure for batch</td>
										<td className="admission-enquiry-fees-structure-table-pdf">
											<Link to="https://gnvsiom.org/assets/pdf/admission/Fees_payment_Batch_2022-23.pdf">
												<FaEye className="admission-enquiry-fees-structure-table-pdf-view" />
											</Link>
										</td>
									</tr>
								</table>
							</div>
						</div>

						<div
							className={
								toggleTabs === 3
									? "active-admission-enquiry-tables admission-enquiry-eligiblity-criteria-container"
									: "admission-enquiry-tables"
							}
							dangerouslySetInnerHTML={{
								__html: data && data.eigibility_criteria,
							}}
						></div>

						<div
							className={
								toggleTabs === 4
									? "active-admission-enquiry-tables"
									: "admission-enquiry-tables"
							}
							dangerouslySetInnerHTML={{
								__html: data && data.course_intake,
							}}
						></div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default AdmissionEnquiry;
