import React, { Fragment, useState } from "react";
import "./admissionpayonline.css";
import { api } from "./../../helper/api";
import { toast } from "react-toastify";
import Loading from "../Loading/Loading";
import {
	EmailInput,
	GenricInput,
	MobileNumberInput,
	SelectField,
	TextInput,
} from "../InputFeilds";
const AdmissionPayOnline = () => {
	const [formData, updateFormData] = useState({
		admission_payment: true,
	});
	const [loading, setLoading] = useState(false);
	const [isValidated, setIsValidated] = useState(false);

	const handleChange = (e) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		const response = await api({
			method: "post",
			url: "inquiry",
			data: formData,
			headers: { "Content-Type": "multipart/form-data" },
		});
		const jsonData = await response.data;

		if (
			jsonData?.status_message === "Form Submitted" &&
			jsonData?.status === 200
		) {
			setLoading(false);
			window.location.reload();
			toast.success("Your Form Submitted Successfully");
		} else {
			setLoading(false);
			toast.error("Something Occur! Please try again later");
		}
	};
	return (
		<Fragment>
			{loading && <Loading />}
			<div className="admission-enquiry-form-container">
				<div className="admission-enquiry-form admission-form-padding">
					<form
						onSubmit={handleSubmit}
						className="admission-enquiry-form-start container"
					>
						<SelectField
							label={"Course/Program/Event"}
							id="course"
							onChange={handleChange}
							placeholder="Select Course/Program/Event"
							options={[
								{
									name: "FDP - INNOVATIVE TECHNIQUES FOR MANAMGEMENT",
									value: "FDP - INNOVATIVE TECHNIQUES FOR MANAMGEMENT",
								},
								{
									name: "International Conference Payment",
									value: "International Conference Payment",
								},
								{
									name: "UGC Care - International Conference Payment",
									value: "UGC Care - International Conference Payment",
								},
								{
									name: "UGC Care - International Conference Payment -6500",
									value: "UGC Care - International Conference Payment -6500",
								},
							]}
							isValidated={isValidated}
							defaultValue={formData?.course}
							required
						/>
						<TextInput
							label={"First Name"}
							id="firstname"
							onChange={handleChange}
							className={"mt-3"}
							placeholder="Enter First Name"
							defaultValue={formData?.firstname}
							isValidated={isValidated}
							required
						/>
						<TextInput
							label={"Last Name"}
							id="lastname"
							onChange={handleChange}
							className={"mt-3"}
							placeholder="Enter Last Name"
							defaultValue={formData?.lastname}
							isValidated={isValidated}
							required
						/>
						<TextInput
							label={"Address Line"}
							id="address_line"
							onChange={handleChange}
							className={"mt-3"}
							placeholder="Enter Address Line"
							defaultValue={formData?.address_line}
							isValidated={isValidated}
							required
						/>
						<div className="admission-enquiry-city-state-pin">
							<div className="admission-enquiry-gender-container">
								<TextInput
									label={"City"}
									id="city"
									onChange={handleChange}
									className={"mt-3"}
									placeholder="Enter your city"
									defaultValue={formData?.city}
									isValidated={isValidated}
									required
								/>
							</div>
							<div className="admission-state-container">
								<TextInput
									label={"State"}
									id="state"
									onChange={handleChange}
									className={"mt-3"}
									placeholder="Enter your state"
									defaultValue={formData?.state}
									isValidated={isValidated}
									required
								/>
							</div>
							<div className="admission-country-container">
								<TextInput
									label={"Country"}
									id="country"
									onChange={handleChange}
									className={"mt-3"}
									placeholder="Enter your country"
									defaultValue={formData?.country}
									isValidated={isValidated}
									required
								/>
							</div>
							<div className="admission-pincode-container">
								<GenricInput
									type="number"
									maxlength={"6"}
									label={"Pincode"}
									id="pincode"
									onChange={handleChange}
									className={"mt-3"}
									placeholder="Enter your pincode"
									defaultValue={formData?.pincode}
									isValidated={isValidated}
									required
								/>
							</div>
						</div>
						<EmailInput
							label={"E-mail"}
							id="email"
							className={"mt-3"}
							onChange={handleChange}
							isValidated={isValidated}
							defaultValue={formData?.email}
							placeholder="Enter email"
							required
						/>
						<div className="admission-mobile-amount-row">
							<div className="admission-mobile-container">
								<MobileNumberInput
									label={"Mobile No."}
									id="phone"
									onChange={handleChange}
									placeholder="Enter The Number"
									className={"mt-3"}
									maxlength={"10"}
									isValidated={isValidated}
									defaultValue={formData?.phone}
									required
								/>
							</div>
							<div className="admission-amount-container">
								<GenricInput
									type="number"
									maxlength={"6"}
									label={"Amount"}
									id="amount"
									onChange={handleChange}
									className={"mt-3"}
									placeholder="Enter  amount"
									defaultValue={formData?.amount}
									isValidated={isValidated}
									required
								/>
							</div>
						</div>
						<div className="admission-form-payment-container">
							<button
								type="submit"
								className="admission-form-payment-btn"
							>
								Submit and proceed with online payment.
							</button>
						</div>
					</form>
				</div>
			</div>
		</Fragment>
	);
};

export default AdmissionPayOnline;
